import React from 'react';
import { createPortal } from 'react-dom';

const modalRoot =
  typeof window === 'undefined' ? null : document.getElementById('modal');
class Modal extends React.Component {
  el = document.createElement('div');

  componentDidMount() {
    modalRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    modalRoot.removeChild(this.el);
  }

  render() {
    return createPortal(this.props.children, this.el);
  }
}

export default Modal;
