import React, { useState } from 'react';
import Container from 'container';
import Headline from 'headline';
import Icon from 'icon';
import Button from 'button';
import { navigate } from 'gatsby';
import BlockList from 'shared/block-list';
import data from './data';

import s from './services-grid.module.scss';

const mappedData = [
  ...data.slice(0, 3),
  { data },
  ...data.slice(3).map(block => ({ ...block, bottom: true }))
];

function BigBlock({ block, active, ...props }) {
  return (
    <div
      className={`${s.bigBlockWrapper} ${
        active === block.icon ? s.active : ''
      }`}
      {...props}
    >
      <div className={s.content}>
        <Headline
          h3
          className={s.blockTitle}
          white
          onClick={() => navigate(block.slug)}
        >
          <Icon icon={block.icon} />
          {block.title}
        </Headline>
        <p>{block.text}</p>
      </div>
      <ul className={s.blockList}>
        {block.list.map(item => (
          <li key={item}>{item}</li>
        ))}
      </ul>
    </div>
  );
}

export default function ServicesGrid() {
  const [active, setActive] = useState(mappedData[0].icon);
  return (
    <Container className={s.root}>
      <div className={s.wrapper}>
        {mappedData.map(({ bottom, ...block }) =>
          block.data ? (
            <div key="big-block" className={`${s.bigBlock} ${s.noMobile}`}>
              {block.data.map(block => (
                <BigBlock
                  block={block}
                  active={active}
                  key={`big-${block.icon}`}
                />
              ))}
            </div>
          ) : (
            <div
              key={block.icon}
              className={`${s.block} ${active === block.icon ? s.active : ''} ${
                bottom ? s.bottom : ''
              }`}
              role="button"
              onClick={() => setActive(block.icon)}
              tabIndex="0"
            >
              <div
                className={`${s.blockWrapper} ${
                  active === block.icon ? s.active : ''
                }`}
              >
                <div>
                  <BlockList {...block} className={s.innerList} />
                </div>
                <div className={s.buttonWrapper}>
                  <Button href={`${block.slug}`}>Learn more</Button>
                </div>
              </div>
              <div className={`${s.bigBlock} ${s.noDesktop}`}>
                <BigBlock block={block} />
              </div>
            </div>
          )
        )}
      </div>
    </Container>
  );
}
