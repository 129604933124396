import React from 'react';
import OurWork from 'shared/our-work';

const data = {
  title: 'Our work',
  subtitle:
    'Interested in our case studies? Here is a list of past work that involved digital, branding, advertising & much more.',
  button1: {
    text: 'SEE MORE WORK',
    href: '/our-work/'
  },
  button2: {
    text: 'READY TO START',
    href: '/contact/'
  },
};

export default function IntroSection({ featuredList }) {
  const cards = featuredList.map(({ featured, slug }) => ({
    title: featured.title,
    subtitle: featured.services_provided,
    small_image: featured.small_image,
    large_image: featured.large_image,
    href: slug,
    showcase: featured.showcase,
  }))
  .filter(({ showcase }) => showcase);
  return <OurWork {...{ cards, ...data }} />;
}
