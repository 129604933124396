const testimonials = [
  {
    quote: 'CXN Collective has played a monumental role in the curation and execution of our business model since inception. Without them we wouldn’t have been this far along in such a short time.',
    author: 'GREG PAULUS',
    client: 'CEO of Cohort CS and Managing Partner of Plexus Healthcare',
    image: 'greg-cannapan',
    customAuthor: false,
  },
  {
    quote: 'CXN really took it upon themselves to help create my vision for this communication platform. These guys really became part of the journey.',
    author: 'JAY ATLAS CONRAD',
    client: 'CEO of The Connections Group',
    image: 'jay-conrad',
    customAuthor: false,
  },
  {
    quote: 'CXN Collective was amazing to work with on our redesign project. They were professional, knowledgeable and a delight to be around.',
    author: 'JORGE MAS',
    client: 'Owner of Inter Miami CF',
    image: 'jorge-mas',
    customAuthor: false,
  }
];

function randomTestimonial() {
  return testimonials.sort(() => Math.random() - 0.5).pop();
}

export const testimonial = randomTestimonial();