import React from 'react';
import Icon from 'icon';

import s from './block-list.module.scss';

export default function BlockList({ icon, title, list, className, ...props }) {
  return (
    <div className={`${s.root} ${className || ''}`} {...props}>
      <p className={s.title}>
        <span className={s.iconWrapper}>
          <Icon icon={icon} className={s.icon} />
        </span>
        {title}
      </p>
      <ul className={s.list}>
        {list.map(item => (
          <li key={item} className={s.listItem}>
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
}
