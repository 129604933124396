export default [
  {
    icon: 'digital',
    title: 'digital marketing',
    list: [
      'Omni Channel Strategy',
      'Persona / Buyer Development',
      'Search Engine Optimization',
      'Social Media Marketing',
      'Prescriptive & Predictive Analytics'
    ],
    slug: '/digital-marketing/',
    text: `
    Your presence on the internet marks your existence in society. To the modern consumer, you are your web presence. It’s as simple as that. Your brands digital experience isn’t something to skimp on, and optimizing every aspect of it gives your business an unlimited reach with amazing opportunities to target specifically the users you want.
    `
  },
  {
    icon: 'branding',
    title: 'DESIGN + BRANDING',
    list: [
      'Brand Development',
      'Web + App UX/UI',
      'Graphic Design',
      'Logo Development',
      'Collateral Design + Print'
    ],
    slug: '/design-and-branding/',
    text: `
    Your brand’s design choices represent you and need to appeal to your target audience. Having a beautiful website to guide the customer on a journey and showcase your vision is the ultimate way to garner trust. Your site’s feel has unlimited possibilities, from professional yet clever to playful yet elegant, and so many more. People are visual. Let us show your users who you are.
    `
  },
  {
    icon: 'web-app',
    title: 'web + app',
    list: [
      'Corporate Website',
      'eCommerce',
      'Mobile App Design + Dev',
      'Headless CMS',
      'CRM / ERP Integration'
    ],
    slug: '/web-app/',
    text: `
    A seamless user experience starts with finding your company’s website quickly, continues with navigating through the site with ease understanding your business’s value, and ends with both a conversion for your brand and a happy customer. Our technological solutions assure your user can easily shop, fill forms, access your information or do anything you need to convert.
    `
  },
  {
    icon: 'advertising',
    title: 'DIGITAL ADVERTISING',
    list: [
      'Media Buying',
      'Paid Search',
      'Paid Social Media',
      'Display Advertising',
      'Real Time ROI Dashboard'
    ],
    slug: '/',
    text: `
    The digital era is now more than ever, and our team is continuously monitoring changes as they emerge – always keeping your business ahead of the pack and creating opportunities that will deliver. Not only do we make digital…personal, but we ensure that your brand speaks to your target audience with the right message.
    `
  },
  {
    icon: 'content',
    title: 'CONTENT MARKETING',
    list: [
      'Content Strategy Development',
      'Industry Specific Content',
      'Consumer Buyer Personas',
      'eBooks & White Papers',
      'Promotional Content'
    ],
    slug: '/content-marketing/',
    text: `
    From on-page copy to informative articles and eBooks, we understand the importance of providing value to your customer. The collaborative culture of our company multiplies our capacity to create content that is more that just creative, focused, and valuable.
    `
  },
  {
    icon: 'development',
    title: 'development',
    list: ['HTML5', 'Sass', 'JavaScript', 'React JS', 'Wordpress'],
    slug: '/contact/',
    text: `
    Your website isn’t just a URL. It’s an essential component of your marketing and business strategy. It’s an extension of your brand and a virtual experience for your consumers. When you’re choosing a site developer, you need someone you can trust to execute every aspect to the highest level of quality.
    `
  }
];
