import React from 'react';
import Container from 'container';
import Headline from 'headline';
import Img from 'img';

import s from './testimonial.module.scss';

export default function Testimonial({
  background,
  quote,
  author,
  client,
  image,
  primary,
  secondary,
  className,
  customAuthor,
  id,
  ...props
}) {
  const primaryClass = primary && s.primary;
  const backgroundClass = primaryClass || '';
  const style = background ? { style: { background } } : {};
  return (
    <Container
      idx={id}
      className={`${s.root} ${!background && backgroundClass} ${className ||
        ''}`}
      {...style}
    >
      <Headline className={s.quote} h2 dash center white el="p">
        {`"${quote}"`}
      </Headline>
      <Img className={s.img} src={image} />
      <Headline className={s.author} h3 center white el="p">
        {author}
        {' '}
        <span className={`${s.primary} ${s.reg} ${customAuthor ? s.customAuthor : ''}`}>{client}</span>
      </Headline>
    </Container>
  );
}
