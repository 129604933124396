import React from 'react';
import Container from 'container';
import Button from 'shared/popup-button';
import Icon from 'icon';

import s from './lets-start.module.scss';

export default function LetsStart({ text, href, id, ...props }) {
  return (
    <Container primary className={s.root} id={id}>
      <Icon icon="dash" className={s.dash} />
      <div className={s.wrapper}>
        <Button
          {...props}
          href={href || '/contact/'}
          popupName="small-form"
          white
          big
          hoverWhite
          className={s.button}
        >
          {text || 'Lets start your project'}
        </Button>
      </div>
    </Container>
  );
}
