import React from 'react';
import Container from 'container';
import SmallForm from 'shared/small-form';
import Icon from 'icon';
import s from './small-form-popup.module.scss';

export default function SmallFormPopup({ popup, ...props}) {
  return (
    <Container className={s.root}>
      <div className={s.wrapper}>
        <div className={s.iconWrapper} onClick={() => popup.hide()}>
          <Icon className={s.icon} icon="times-circle" />
        </div>
        <SmallForm
          title="GET IN TOUCH!"
          subtitle="Don’t know how to start? We can help."
        />
      </div>
    </Container>
  );
}
