import React from 'react';
import { navigate } from '@reach/router';
import Img from 'img';
import { Link } from 'gatsby';

import s from './collage-grid.module.scss';

function ImageWrapper({ src, title, subtitle, href }) {
  return (
    <div
      className={s.imageWrapper}
      onClick={() => navigate(href)}
      tabIndex="0"
      role="link"
    >
      <div className={s.contentImage}>
        <Img src={src} className={s.image} />
      </div>
      <Link className={s.content} to={href}>
        <div className={s.blur} />
        <p className={s.name}>{title}</p>
        <p className={s.type}>{subtitle}</p>
      </Link>
    </div>
  );
}

export default function CollageGrid({ images, className }) {
  const shuffled = images.sort(() => Math.random() - 0.5);
  return (
    <div className={`${s.root} ${className || ''}`}>
      <div className={`${s.child} ${s.childWrapper}`}>
        {shuffled[0] && (
          <ImageWrapper
            src={shuffled[0].small_image.title}
            title={shuffled[0].title}
            subtitle={shuffled[0].subtitle}
            href={shuffled[0].href}
          />
        )}
        {shuffled[1] && (
          <ImageWrapper
            src={shuffled[1].small_image.title}
            title={shuffled[1].title}
            subtitle={shuffled[1].subtitle}
            href={shuffled[1].href}
          />
        )}
      </div>
      <div className={`${s.child} ${s.large}`}>
        {shuffled[2] && (
        <ImageWrapper
          src={shuffled[2].large_image.title}
          title={shuffled[2].title}
          subtitle={shuffled[2].subtitle}
          href={shuffled[2].href}
          />
        )}
      </div>
    </div>
  );
}
