import React from 'react';
import ImageSection from 'shared/image-section';

import s from './image-message.module.scss';

export default function ImageMessage() {
  return (
    <ImageSection className={s.root} src="cxn-palm-trees2x">
      <img src="/svg/cxn-story-together.svg" alt="" className={s.img} />
    </ImageSection>
  );
}
