import React from 'react';
import Headline from 'headline';
import Button from 'button';
import { useFormInput, useErrors, useSubmitReady } from '@/hooks/useForm';
import axios from 'axios';
import { navigate } from 'gatsby';
import s from './small-form.module.scss';

export default function SmallForm({ title, subtitle, ...props }) {
  // Inputs
  const name = useFormInput('');
  const email = useFormInput('');
  const phone = useFormInput('');
  const msg = useFormInput('');

  // Error handlers
  const emailErrors = useErrors({ email: email.state });
  const nameErrors = useErrors({ name: name.state });
  const phoneErrors = useErrors({ phone: phone.state });
  const msgErrors = useErrors({ msg: msg.state });

  // Sumbit handlers
  const submitReady = useSubmitReady({
    name: name.state,
    email: email.state,
    msg: msg.state
  });
  const handleSubmit = async e => {
    e.preventDefault();
    const payload = {
      name: name.state.value,
      email: email.state.value,
      phone: phone.state.value,
      msg: msg.state.value,
      page: window.location.pathname,
      date: new Date().toDateString()
    };
    console.log(payload, 'payload');
    await axios(
      'https://cxn-agency.firebaseio.com/small-form.json',
      {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        data: payload
      }
    )
      .then(x => {
        console.log(x);
        navigate('/thanks/');
      })
      .catch(err => console.log(err));
  };
  return (
    <form className={`${s.form}`} onSubmit={handleSubmit}>
      <Headline bold white className={s.title} h2>
        {title}
      </Headline>
      <Headline className={s.subtitle} h3 el="p">
        {subtitle}
      </Headline>
      <input
        type="text"
        name="name"
        placeholder="Full Name *"
        value={name.state.value}
        {...name.events}
        className={`${s.input} ${nameErrors.name && 'error'}`}
      />
      <input
        type="text"
        name="email"
        placeholder="Email *"
        value={email.state.value}
        {...email.events}
        className={`${s.input} ${emailErrors.email && 'error'}`}
      />
      <input
        type="text"
        name="phone"
        placeholder="Phone"
        value={phone.state.value}
        {...phone.events}
        className={`${s.input} ${phoneErrors.phone && 'error'}`}
      />
      <textarea
        name="msg"
        id="msg"
        placeholder="How can CXN help you? *"
        value={msg.state.value}
        {...msg.events}
        className={`${s.textarea} ${msgErrors.msg && s.error}`}
      />
      <div className={s.buttonWrapper}>
        <Button className={s.button} type="submit" disabled={!submitReady}>
          LET'S TALK
        </Button>
      </div>
    </form>
  );
}
