import { useState } from 'react';

export const usePopup = () => {
  const [selectedPopup, setSelectedPopup] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  function show(num = 0) {
    setSelectedPopup(num);
    setShowPopup(true);
  }
  function hide() {
    setShowPopup(false);
  }
  return {
    popup: {
      selected: selectedPopup,
      isShowing: showPopup,
      show,
      hide
    }
  };
};
