import React from 'react';
import Container from 'container';
import Headline from 'headline';
import Button from 'button';

import s from './intro-section.module.scss';

export default function IntroSection() {
  return (
    <Container className={s.root}>
      <Headline h2 dash smallCenter>
        Driving Customer Experiences
      </Headline>
      <Headline h1 className={s.title}>
        Creating Brand Identities
      </Headline>
      <p className={s.text}>
        We know a strong presence in the digital realm only happens by combining
        methods and mediums. A single channels or platform is not enough. We do
        what it takes to connect to your target audience, and recommend the
        scope of services best suited to help you connect with users and find
        the success you want! Just let us do our thing; and watch your brand
        evolve!
      </p>
      <Button href="/contact">Lets start something</Button>
    </Container>
  );
}
