import React from 'react';
import Meta from 'meta';
import Hero from 'components/page-what-we-do/hero';
import IntroSection from 'components/page-what-we-do/intro-section';
import ServicesGrid from 'components/page-what-we-do/services-grid';
import OurWork from 'components/page-what-we-do/our-work';
import Testimonial from 'shared/testimonial';
import LetsStart from 'shared/lets-start';
import { testimonial } from 'utils/randomTestimonial';
import ImageMessage from 'components/page-what-we-do/image-message';

export default function WhatWeDoPage({ pageContext, ...props }) {
  const { featuredList } = pageContext;
  return (
    <>
      <Meta
        title="Build Your Website and Reach Your Audience | CXN"
        description="Our complementary services follow a strategic approach that fully addresses your user’s experience and your company’s conversion goals."
        canonical="https://cxncollective.com/what-we-do/"
      />
      <Hero />
      <main>
        <IntroSection />
        <ServicesGrid />
        <OurWork {...{ featuredList }} />
        <Testimonial {...testimonial} />
        <ImageMessage />
        <LetsStart />
      </main>
    </>
  );
}
