import React from 'react';
import Container from 'container';
import Headline from 'headline';
import Button from 'shared/popup-button';
import CollageGrid from 'shared/collage-grid';

import s from './our-work.module.scss';

export default function IntroSection({
  title,
  subtitle,
  cards,
  button1,
  button2,
  ...props
}) {
  return (
    <Container className={s.root}>
      <Headline h1 className={s.title} dash smallCenter>
        {title}
      </Headline>
      <p className={s.text}>{subtitle}</p>
      <CollageGrid images={cards} />
      <div className={s.buttons}>
        {Object.keys(button1).length > 0 && (
          <Button
            href={button1.href || '/our-work/'}
            secondary
            className={s.moreWork}
          >
            {button1.text}
          </Button>
        )}
        {Object.keys(button2).length > 0 && (
          <Button popupName="small-form" href={button2.href || '/contact/'} {...button2}>
            {button2.text}
          </Button>
        )}
      </div>
    </Container>
  );
}
