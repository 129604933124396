import React from 'react';
import Container from 'container';
import Img from 'img';

import s from './image-section.module.scss';

export default function ImageSection({ children, className, src }) {
  return (
    <Container className={`${s.root} ${className || ''}`}>
      <div className={s.imageWrapper}>
        <Img src={src} className={s.img} />
      </div>
      <div className={`${s.content} content`}>{children}</div>
    </Container>
  );
}
