import React from 'react';
import Button from 'button';
import PopupGenerator from 'components/popup-generator';
import { usePopup } from '@/hooks/usePopup';

export default function PopupTrigger({
  className,
  children,
  popupName,
  ...props
}) {
  const { popup } = usePopup();
  return (
    <>
      <Button
        className={className || ''}
        onClick={() => popup.show()}
        {...props}
      >
        {children !== undefined ? children : props.text}
      </Button>
      {props.popup_trigger && <PopupGenerator {...{ popup, popupName, ...props }} />}
    </>
  );
}
